import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';

const DEBUG = false;
const empty = { menus: [], loading: true };
const roleMap = {
	anonymous: 'anonymous',
	cf_admin: 'cf_admin',
	cf_client: 'cf_client',
	cf_propertyowner: 'cf_propertyowner',
	cf_it: 'cf_propertyowner',
	cf_tech: 'cf_propertyowner',
	cf_management: 'cf_propertyowner',
	cf_support: 'cf_propertyowner',
	cf_tenant: 'cf_tenant',
	cf_admin_readonly: 'cf_admin_readonly',
	cf_partner_entrepreneur: 'cf_partner_entrepreneur',
	cf_contractor_pm: 'cf_contractor_pm',
	cf_contractor_installer: 'cf_contractor_installer',
};

const useRbacSettingsHook = () => {
	const account = useSelector((redux) => redux.account);
	const rbac = useSelector((redux) => redux.rbac);

	const isImpersonating = Cookies.get('impersonateUserUUID') !== undefined;
	const rbacUserRoleImpersonated = Cookies.get('rbacUserRoleImpersonated');

	const getRoleAccess = useCallback(() => {
		DEBUG && console.log('ROLE', account, rbac);

		if (typeof window === 'undefined') return empty;
		if (!account || Object.keys(rbac).length === 0) return empty;

		const plainRole = account?.tokenPayload?.roles?.[0];
		let role = roleMap[plainRole] !== undefined ? roleMap[plainRole] : 'anonymous';
		if (isImpersonating && rbacUserRoleImpersonated) {
			role =
				roleMap[rbacUserRoleImpersonated.toLowerCase()] !== undefined
					? roleMap[rbacUserRoleImpersonated.toLowerCase()]
					: 'anonymous';
		}

		if (!role) {
			console.log('Account token is missing role information');
			return empty;
		}

		if (!rbac[role] || !rbac[role].menus) {
			console.log('No access data for role ' + role);
			return { menus: [], loading: true };
		}

		DEBUG && console.log('Menu RBA', rbac[role]);
		DEBUG && console.log('ROLE', role);
		return rbac[role];
	}, [account?.tokenPayload?.roles?.[0], rbac, isImpersonating, rbacUserRoleImpersonated]);

	return { getRoleAccess };
};

export default useRbacSettingsHook;
